import React from 'react'
import App from './src/App'
import './src/styles/main.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

/**
 * gatsby browser api exports
 * https://www.gatsbyjs.org/docs/browser-apis/
 */

// https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
export const wrapRootElement = ({ element }) => (
  <App element={element} />
)
