import { createGlobalStyle } from 'styled-components'
import { Theme } from '@pga/pga-component-library'

const GlobalStyle = createGlobalStyle`
  h1, h2, h3, h4, h5, h6 {
    font-family: ${Theme.fonts.PlayfairDisplay};
    color: ${Theme.colors.primary};
  }
  p, a {
    font-family: ${Theme.fonts.Montserrat};
    /* font-size: 14px; */
    line-height: 23px;
  }
  li {
    font-family: ${Theme.fonts.Montserrat};
    /* font-size: 15px; */
    line-height: 23px;
  }
  img {
    height: auto;
    max-width: 100%;
  }
  .title-serif {
    a {
      font-family: ${Theme.fonts.PlayfairDisplay};
    }
  }
  /* responsive table in smaller screen */
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
`

export default GlobalStyle
