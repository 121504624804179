import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { getToken, getImpersonationHeader } from '../lib/auth'

export const getAuthHeader = token => token && { Authorization: `Bearer ${token}` }

const cache = new InMemoryCache({})

const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}/graphql`,
  cache,
  request: async (operation) => {
    const token = await getToken()
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...getAuthHeader(token),
        ...getImpersonationHeader()
      }
    }))
  }
})

export default apolloClient
