import React from 'react'

let AuthProvider,
  PrivateRouteGatsby,
  RedirectToLogout,
  withAuthProvider,
  getToken,
  getImpersonationHeader,
  ImpersonationOverlay

// avoid "window is not defined" error during gatsby build
if (typeof window !== "undefined") {
  const impersonation = require('@pga/auth-flow-provider').impersonation
  getImpersonationHeader = impersonation.getImpersonationHeader
  ImpersonationOverlay = impersonation.ImpersonationOverlay

  if (process.env.REACT_APP_AUTH_IMPL === 'auth0') {
    const AuthFlowProvider = require('@pga/auth-flow-provider').auth0.AuthFlowProvider
    const USER_QUERY = require('../queries').USER_QUERY
    const fetchCurrentUser = async (token) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}/graphql`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            ...getImpersonationHeader()
          },
          body: JSON.stringify({ query: USER_QUERY.loc.source.body })
        })

        const json = await res.json()
        const currentUser = json && json.data && json.data.me
        if (!currentUser) {
          throw new Error('No user found')
        }
        return [currentUser, null]
      } catch (err) {
        return [null, err]
      }
    }
    const { LoadingComponent } = require('@pga/pga-component-library')
    const renderRedirecting = () => (<LoadingComponent />)
    AuthProvider = ({ children }) => (
      <AuthFlowProvider fetchCurrentUser={fetchCurrentUser} renderRedirecting={renderRedirecting}>
        {children}
      </AuthFlowProvider>
    )

    PrivateRouteGatsby = require('@pga/auth-flow-provider').auth0.PrivateRouteGatsby
    RedirectToLogout = require('@pga/auth-flow-provider').auth0.RedirectToLogout
    withAuthProvider = require('@pga/auth-flow-provider').auth0.withAuthProvider
    getToken = require('@pga/auth-flow-provider').auth0.getToken
  } else {
    const AuthFlowProvider = require('@pga/auth-flow-provider').onelogin.AuthFlowProvider
    const apolloClient = require('../clients/apollo').default
    const USER_QUERY = require('../queries').USER_QUERY
    AuthProvider = ({ children }) => (
      <AuthFlowProvider apolloClient={apolloClient} sessionCheckInterval={60000} query={USER_QUERY}>
        {children}
      </AuthFlowProvider>
    )

    PrivateRouteGatsby = require('@pga/auth-flow-provider').onelogin.PrivateRouteGatsby
    RedirectToLogout = require('@pga/auth-flow-provider').onelogin.RedirectToLogout
    withAuthProvider = require('@pga/auth-flow-provider').onelogin.withAuthProvider
    getToken = require('@pga/auth-flow-provider').onelogin.getToken
  }
} else {
  AuthProvider = ({ children}) => <>{children}</>
  PrivateRouteGatsby = ({component}) => component
  RedirectToLogout = () => null
  withAuthProvider = () => (Component) => (props) => <Component {...props} />
  getToken = () => Promise.resolve('')
  getImpersonationHeader = () => ''
  ImpersonationOverlay = () => null
}

export {
  AuthProvider,
  PrivateRouteGatsby,
  RedirectToLogout,
  withAuthProvider,
  getToken,
  getImpersonationHeader,
  ImpersonationOverlay
}
