import { pathOr, anyPass } from 'ramda'

const ALLOWED_TYPES = ['MB', 'ST', 'AP', 'PQ']
const ALLOWED_ROLES = ['STAFF', 'SECTION_STAFF', 'MEMBER', 'PGA_MEMBERSHIP_PROGRAM_USER', 'ADMIN']
const RECIPROCITY_LEVELS = ['Z', 'N']

const isAllowedTypes = me => ALLOWED_TYPES.includes(me.type)
const isAllowedRoles = me => ALLOWED_ROLES.includes(me.role)

const isReciprocityCandidate = me => {
  const currentLevel = pathOr('', ['education', 'currentLevel'], me)
  const [levelChar] = currentLevel.split('')
  return me.type === 'QE' && RECIPROCITY_LEVELS.includes(levelChar)
}

const isAllowedAccess = anyPass([isAllowedTypes, isAllowedRoles, isReciprocityCandidate])

export default isAllowedAccess
