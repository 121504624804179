import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { Theme, LoadingComponent } from '@pga/pga-component-library'
import { AuthProvider, PrivateRouteGatsby, withAuthProvider } from './lib/auth'
import { ThemeProvider } from 'styled-components'

import apolloClient from './clients/apollo'
import AccessDenied from './components/AccessDenied'
import { isAllowedAccess, isTerminatedRules, isTerminatedStaff } from '../utils'

const WithPrivateRoute = (props) => {
  const { me, element, authReady, isLoggedIn } = props

  if (!authReady) return <LoadingComponent />

  const meReady = authReady && me

  if (meReady && isTerminatedRules(me) && !isTerminatedStaff(me)) return <AccessDenied />

  if ((meReady && isAllowedAccess(me)) || !isLoggedIn) {
    return <PrivateRouteGatsby component={element} loader={LoadingComponent} key={1} path='/' />
  } else {
    return <AccessDenied />
  }
}

const PrivateRoute = withAuthProvider(WithPrivateRoute)

const WithAuthProvider = ({ element }) => (
  <ApolloProvider client={apolloClient}>
    <AuthProvider>
      <ThemeProvider theme={Theme}>
        <PrivateRoute element={element} />
      </ThemeProvider>
    </AuthProvider>
  </ApolloProvider>
)

export default ({ element }) => {
  return <WithAuthProvider element={element} />
}
