import { gql } from 'apollo-boost'

export const SUBSCRIPTION_QUERY = gql`
  {
    me {
      id
      education {
        subscriptions {
          productId
          startDate
          endDate
        }
        levels {
          levelCd        
          completeCd
          startDate
          endDate
        }
      }
    }
  }
`

export const REGISTER_MUTATION = gql`
  mutation Register($id: ID!, $productCode: String!) {
    register(id: $id, productCode: $productCode) {
      id
    }
  }
`

export const USER_QUERY = gql`
  query USER_QUERY {
    me {
      id
      firstName
      lastName
      type
      class
      photo
      primaryEmail
      role
      phoneNumber
      education {
        currentLevel
      }
      termination {
        status
        terminateCd
        terminateDate
      }
    }
  }
`
