import moment from 'moment'
import { path, pathOr, T, F, cond } from 'ramda'
import { isTerminated } from './index'

const checkTermForTypes = me => ['ST', 'AP', 'QE'].includes(me.type)

// This rule applies for 4 months from the date when terminate code is added. After 4 months, user loses all access.
const allowFourMonthsAccess = (me) => {
  const terminateDate = path(['termination', 'terminateDate'], me)
  const fourMonthsFromTermDate = moment(terminateDate).add(4, 'month')
  return !(moment(fourMonthsFromTermDate).isAfter(moment()))
}

const termCodeRules = {
  GK: {
    forTypes: ['AP', 'QE'],
    predicateFn: T
  },
  TE: {
    forTypes: ['AP', 'QE'],
    predicateFn: T
  },
  TG: {
    forTypes: ['ST'],
    predicateFn: allowFourMonthsAccess
  }
}

// predicate function returns false if user is allowed access to dashboard
const isTerminatedByCodes = me => {
  const terminateCd = path(['termination', 'terminateCd'], me)
  const termCodeRule = pathOr({}, [terminateCd], termCodeRules)
  if (termCodeRule.forTypes && termCodeRule.forTypes.includes(me.type)) {
    return termCodeRule.predicateFn(me)
  }
  return true
}

const terminationRules = cond([
  [checkTermForTypes, isTerminatedByCodes],
  [T, F]
])

export default me => isTerminated(me) ? terminationRules(me) : false
