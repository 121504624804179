import { path, allPass } from 'ramda'

import isAllowedAccess from './isAllowedAccess'
import isTerminatedRules from './isTerminatedRules'

const isTerminated = me => path(['termination', 'status'], me) === 'Terminated'
const isStaff = ({ primaryEmail }) => (primaryEmail || '').endsWith('@pgahq.com')
const isTerminatedStaff = allPass([isStaff, isTerminated])

export {
  isAllowedAccess,
  isTerminatedRules,
  isTerminated,
  isTerminatedStaff
}
