import React from 'react'
import { values, compose } from 'ramda'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import { Header, IconsBox, Nav, MobileNavigation, Theme, IframeRolex } from '@pga/pga-component-library'
import { withAuthProvider } from '../../lib/auth'
import AuthHeader from './AuthHeader'
import { withEmailMigrationData, withSetEmailMigrationMutation } from './query'
import logo from '../../images/pga-logo.svg'

const NavigationBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const SideNavLink = styled(Link)`
  font-family: ${Theme.fonts.Montserrat};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  position: relative;

  &.active::before {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -10px;
    border-bottom: 4px solid;
  }

  :hover, &.active {
    text-decoration: none;
    color: #fff;
  }
`

const LinkStyled = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  font-family: ${Theme.fonts.Montserrat};

  width: 100%;
  flex-grow: 45;
  position: relative;

  &.active::before {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${Theme.colors.gold};
    bottom: -31px;
    border-bottom: 4px solid;
  }

  &:hover {
    color: ${Theme.colors.gold};
  }

  &.active {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
  }
`

const dropdownLinks = [
  {
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Email',
    external: true,
    target: '_blank'
  }
]

const parseEdges = (edges) => {
  return edges.map(edge => values(edge)[0])
}

const setRootActive = (options, location) => 
  location.pathname.includes('/my-membership/pga-frisco')
    ? options.map(o => 
      !o.external ? ({
        ...o,
        root: location.pathname.includes(o.to) && o.to !== '/'
      })
      : o
    )
    : options

export const HeaderWrapper = ({ me, isLoggedIn, authReady, emailMigrationData, emailMigrationMutation, location }) => {
  const logoLink = 'https://account.pga.org'
  const { allNavigationYaml, allNavigationMobileYaml } = useStaticQuery(allNavigationData)
  const options = parseEdges(allNavigationYaml.edges)
  const navMobileOptions = parseEdges(allNavigationMobileYaml.edges)
  const mobileOptions = [...options, ...navMobileOptions]

  const { loading, meEmailMigrationData: migrationData } = emailMigrationData || {}
  const meEmailMigrationData = migrationData || {}
  const { id, doNotShowAgain } = meEmailMigrationData

  const onToggleDoNotShowAgain = () => {
    emailMigrationMutation({ variables: { id, input: { doNotShowAgain: !doNotShowAgain } } })
  }

  return (
    <Header {...{ logo, bgColor: Theme.colors.primary, logoLink }}>
      <MediaQuery minWidth={992}>
        <NavigationBox>
          <Nav options={location ? setRootActive(options, location) : options} comp={LinkStyled} />
          <div className='d-flex align-items-center'>
            <IframeRolex />
            <IconsBox {...{ isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain, me }} />
          </div>
          <AuthHeader {...{ isLoggedIn, me, loading, options: dropdownLinks, meEmailMigrationData, onToggleDoNotShowAgain }} />
        </NavigationBox>
      </MediaQuery>
      <MediaQuery maxWidth={991}>
      {authReady
        ? <MobileNavigation {...{ linkComponent: SideNavLink, options: mobileOptions, me, comp: SideNavLink, b: -7, l: 11, isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain }} vertical />
        : null
      }
      </MediaQuery>
    </Header>
  )
}

export const allNavigationData = graphql`
  query {
    allNavigationYaml {
      edges {
        node {
          id
          label
          to
          root
          external
        }
      }
    }
    allNavigationMobileYaml {
      edges {
        node {
          id
          to
          label
          external
          target
        }
      }
    }
  }
`

export default compose(
  withEmailMigrationData,
  withSetEmailMigrationMutation,
  withAuthProvider
)(HeaderWrapper)
