import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import Header from './Header'
import Footer from './Footer'
import GlobalStyles from '../styles/global'
import { ImpersonationOverlay } from '../lib/auth'

const Div = styled.div`
  background-color: white;
`

const Main = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const Box = styled.div`
  height: 350px;
  width: 700px;
  border: 1px solid ${Theme.colors.darkgrey};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767.98px) {
    border: none;
    width: 80%;

  }
`

const HeaderText = styled.h1`
  font-family: ${Theme.fonts.Montserrat};
  color: ${Theme.colors.primary};
  font-size: 26px;
  font-weight: bold;
`

const Text = styled.p`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 16px;
`

const Anchor = styled.a`
  color: white;
`

const Btn = styled.div`
  margin: 30px;
  height: 60px;
  width: 250px;
  background-color: ${Theme.colors.lightBlue};
  border: 1px solid ${Theme.colors.lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`

export default () => (
  <Div>
    <ImpersonationOverlay />
    <Header />
    <Main>
      <Box>
        <HeaderText>Restricted Content</HeaderText>
        <Text>Sorry, access to content in this area is restricted.</Text>
        <Btn><Anchor href='https://account.pga.org'>My Account</Anchor></Btn>
      </Box>
    </Main>
    <Footer />
    <GlobalStyles />
  </Div>
)
